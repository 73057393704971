<template>
  <div class="choose-terminal">
      <p class="title">华油转发终端配置</p>
    <div class="dom">
      <el-transfer
        style="text-align: left; display: inline-block"
        filterable
        :filter-method="filterMethod"
        filter-placeholder="请输入终端号"
        v-model="value"
        :render-content="renderFunc"
        :titles="['可选终端', '已配置终端']"
        :data="deviceList"
      ></el-transfer>
    </div>
    <div class="footer">
      <el-button @click="submit" type="primary" :disabled="loading">提交</el-button>
    </div>
  </div>
</template>
<script>
import {
  getTerminalList,
  saveDevice
} from "@/api/lib/api.js";
export default {
  data () {
    return {
      visible: false,
      value: [],
      deviceList: [],
      loading: false,
    }
  },
  mounted () {
    this.queryDeviceData();
  },
  methods: {
    async queryDeviceData () {
      const res = await getTerminalList();
      // const res = await getTerminalList({ deviceNos: [] });
      if (res.code === 1000) {
        const { availableList = [], configuredList = [] } = res.data;
        const all = [...availableList, ...configuredList]
        this.deviceList = all.map(item => ({
          key: item.deviceNo,
          label: item.cph
        }));
        this.value = configuredList.map(item => item.deviceNo)
      }
    },
    filterMethod (query, item) {
      if (query) {
        // if (item?.label) {
        //   return item.key.split(query).length > 1 || item.label.split(query).length > 1;
        // } else {
        //   return item.key.split(query).length > 1;
        // }
        return item.key.split(query).length > 1;
      } else {
        return true;
      }
    },
    renderFunc (h, option) {
      return <span>{option.key}</span>
    },
    submit () {
      const data = {
        deviceNos: this.value
      }
      this.loading = true;
      saveDevice(data).then(res => {
        if (res.code === 1000) {
          this.queryDeviceData();
          this.$message.success(res.msg)
        } else {
          this.$message.error(res.msg);
        }
        this.loading = false;
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.choose-terminal {
  background: #fff;
  height: 100%;
  width: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.title{
  margin-bottom: 20px;
}
.dom {
  text-align: center;
}
.footer {
  text-align: right;
  margin-right: 20px;
}
/deep/.el-transfer-panel {
  width: 25vw;
  height: 100%;
}
/deep/.el-transfer-panel__body {
  height: 62vh;
}
/deep/ .el-transfer-panel__list.is-filterable {
  height: calc(100% - 65px);
}
</style>
