var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "choose-terminal" }, [
    _c("p", { staticClass: "title" }, [_vm._v("华油转发终端配置")]),
    _c(
      "div",
      { staticClass: "dom" },
      [
        _c("el-transfer", {
          staticStyle: { "text-align": "left", display: "inline-block" },
          attrs: {
            filterable: "",
            "filter-method": _vm.filterMethod,
            "filter-placeholder": "请输入终端号",
            "render-content": _vm.renderFunc,
            titles: ["可选终端", "已配置终端"],
            data: _vm.deviceList
          },
          model: {
            value: _vm.value,
            callback: function($$v) {
              _vm.value = $$v
            },
            expression: "value"
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "footer" },
      [
        _c(
          "el-button",
          {
            attrs: { type: "primary", disabled: _vm.loading },
            on: { click: _vm.submit }
          },
          [_vm._v("提交")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }